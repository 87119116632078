import React, { useEffect, useState } from "react"
import "./Orders.css"
import axiosConfig from "../Service/axiosConfig"
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import Masters from "../Components/masters.json";
import { FormParser, PopupModal } from "../Components"
const ShippingInstructions = ({ show, setShow, orderId, setLoadingButton, setActive, CountDisplay, OderSCount }) => {
    const [shippingshow, setShippingShow] = useState(true)
    const [smshow, setSmshow] = useState(false)
    const [Assign, setAssign] = useState(false)
    const [other, setOther] = useState(false)
    const [ShippingMethod, setShippingMethod] = useState([])
    const [DeliveryAgents, setDeliveryAgents] = useState([])
    const [Deliveradd, setDeliverAdd] = useState(false)
    const [Data, setData] = useState({})
    const [orderImage, setorderImage] = useState(false)
    const [AddPhoto, setAddPhoto] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [popupType, setpopupType] = useState("");
    const [message, setMessage] = useState("");
    const MastersModal = Masters.DeliveryAgents;
    const handleFileChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const name = "order_item_photo"
        console.log(file)
        setImageFile(file);
        setData({ ...Data, [name]: file })
    };
    // const HandleFiles = (e) => {
    //     e.preventDefault();
    //     const file = e.target.files[0];
    //     const name = e.target.name;
    //     const value = file;
    //     SetData({ ...Data, [name]: value });
    // };
    const HandleChange = (name, event) => {
        console.log(name, event)
        setData({ ...Data, [name]: event });
    };
    const HandleselectChange = (selectedOption, name) => {
        setData({ ...Data, [name]: selectedOption?.value ? selectedOption?.value : "" });
    }
    const handleShipping = (method, id) => {
        console.log(method, id)
        setShippingShow(false)
        if (method === "Self Shipping") {
            setSmshow(true)
            setOther(false)
            HandleChange("shipping_method", id)
        }
        if (method === "Other") {
            setSmshow(false)
            setOther(true)
            HandleChange("shipping_method", id)
        }
    }
    const handleAssign = (Assign) => {
        console.log(Assign)
        if (Assign === "Auto") {
            setSmshow(false)
            HandleChange("delivery_type", Assign)
            setorderImage(true)
            // handleUpdated()
        }
        if (Assign === "Manual") {
            setSmshow(false)
            setAssign(true)
            HandleChange("delivery_type", Assign)
            GetDeliveryAgents()
        }
    }
    const handleDeliveryAgent = (event) => {
        event.preventDefault()
        setAssign(false)
        setorderImage(true)
    }
    const HandleTracking = (e) => {
        e.preventDefault();
        setOther(false)
        setorderImage(true);
    }
    const handleopenImage = () => {
        setorderImage(true)
        setAddPhoto(true)
        document.getElementById('fileInput').click();
    }
    const handleSkip = () => {
        console.log("skip")
        handleUpdated()
    }
    console.log(Data, "Data")
    const handleUpdated = async () => {
        console.log("shipId", Data)
        console.log(orderId, "orderid")
        let Data_to_send = {
            orderstatus: "Accepted",
            "shipping_method": Data.shipping_method,
        }
        let Data_to_post = {
            "delivery_type": Data.delivery_type,
            "delivery_agent": Data.delivery_agent,
            "order_item_photo": Data.order_item_photo,
            "tracking_url": Data.tracking_url
        }
        console.log(Data_to_send, "Data_to_send", Data_to_post)
        try {
            const response = await axiosConfig.post(`/accounts/orders/${orderId}/order_status_update/`, Data_to_send, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            console.log(response, "response")
            const responce = await axiosConfig.post(`/accounts/order-shipments/${orderId}/order_shipment/`, Data_to_post, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setShow(false)
            console.log(responce, "response")
            setLoadingButton(false)
            setActive("Accepted")
            CountDisplay()
            OderSCount(["Placed", "Accepted"])
            setMessage("Order and Order Status updated Successfully!")
        }
        catch (error) {
            console.log(error);
            setShow(false)
        }
    }
    const GetDeliveryAgents = async () => {
        try {
            let resp = await axiosConfig.get(`/deliveryagent/delivery-agents/?page_size=500&is_suspended=false&fields_user=id,full_name,mobileno,email,role,last_login`)
            console.log(resp.data.results)
            setDeliveryAgents(resp.data.results)
        } catch (error) {
            console.log(error);
        }
    }
    const GetShippingMetthods = async () => {
        try {
            let resp = await axiosConfig.get(`/accounts/shipping-methods/?page_size=500&is_suspended=false`)
            console.log(resp.data.results)
            setShippingMethod(resp.data.results)
        } catch (error) {
            console.log(error);
        }
    }
    const formSubmit = (FormData) => {
        console.log(FormData, "formdata");
        axiosConfig.post("/deliveryagent/delivery-agents/", FormData)
            .then((res) => {
                console.log(res);
                HandleChange("delivery_agent", res.data.id)
                setMessage("Delivery agent Added Successfully!")
                setpopupType("success");
                setDeliverAdd(false);
                setorderImage(true)
            })
            .catch((error) => {
                console.log(error);
                setDeliverAdd(true);
                setpopupType(error);
            });
    }
    useEffect(() => {
        if (show === true) {
            setData({})
            GetShippingMetthods()
        }
        console.log(show)
    }, [show])
    return (
        <>
            {shippingshow &&
                <div>
                    <h4 style={{ textAlign: 'center', paddingBottom: '16px' }}>Select Shipping Method</h4>
                    <div className="ship-cont">
                        {ShippingMethod.map((Data, index) => {
                            return (
                                <div className="shipping-types" key={index} onClick={() => handleShipping(Data.shipping_method_name, Data.id)}>{Data.name}</div>
                            )
                        })}
                    </div>
                </div>
            }
            {smshow &&
                <div>
                    <h4 style={{ textAlign: 'center', paddingBottom: '16px' }}>Select Self Shipping</h4>
                    <div className="ship-cont">
                        <div className="shipping-types" onClick={() => handleAssign("Auto")}>Auto Assign</div>
                        <div className="shipping-types" onClick={() => handleAssign("Manual")}>Manual Assign</div>
                    </div>
                </div>
            }
            {Assign &&
                <div>
                    <div style={{ textDecoration: 'underline', textAlign: 'right', marginBottom: '16px', color: '#1b7d78' }} onClick={() => { setDeliverAdd(true); setAssign(false) }}>+Add New</div>
                    <form onSubmit={(e) => { handleDeliveryAgent(e) }}>
                        <Select
                            options={DeliveryAgents.map((res) => ({ "value": res.id, "label": res.user?.full_name }))}
                            placeholder="Select Delivery-Agent"
                            value={DeliveryAgents.map(res => ({ "value": res.id, "label": res.user?.full_name })).find(res => res.value === Data.delivery_agent)}
                            onChange={(selectedOption) => HandleselectChange(selectedOption, "delivery_agent")}
                            required
                        />
                        <button type="submit" className="btn btn-primary btn-block" style={{ marginTop: '16px' }}>Assign</button>
                    </form>
                </div>
            }
            {other &&
                <div>
                    <form onSubmit={HandleTracking}>
                        <div className="form-group">
                            <label htmlFor="validationCustom01" >Tracking URL : </label>
                            <div className="nuput-box">
                                <input type="url" className="form-control" id="validationCustom01" placeholder="Paste/ Tracking Url" name="tracking_url" value={Data.tracking_url} onChange={(event) => HandleChange("tracking_url", event.target.value)} />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </form>
                </div>
            }
            {orderImage &&
                <div>
                    <h4 style={{ textAlign: 'center', paddingBottom: '16px' }}>Add orders photos to improve quality</h4>
                    <button className="btn btn-primary btn-block" style={{ marginBottom: '16px', padding: '7px' }} onClick={handleopenImage}> <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} accept="image/*" />Add Items Photo</button>
                    {imageFile && (
                        <div>
                            <h4>Selected Image Preview:</h4>
                            <img src={URL.createObjectURL(imageFile)} alt="Selected" style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} />
                            <button type="submit" className="btn btn-primary" onClick={() => { handleUpdated() }}>Submit</button>
                        </div>
                    )}
                    <div style={{ textDecoration: 'underline', textAlign: "right", paddingBottom: '16px', color: '#1b7d78' }} onClick={() => handleSkip()}>Skip</div>
                </div>
            }

            {Deliveradd &&
                <>

                    <h4 style={{ textAlign: 'center', paddingBottom: '16px' }}>Add DeliveryAgent</h4>
                    <FormParser
                        modelObject={MastersModal}
                        formSubmit={formSubmit}
                        error={Error}
                        buttonTitle="Save"
                    />
                </>
            }
            {popupType !== "" && (
                <PopupModal
                    setpopupType={setpopupType}
                    popupType={popupType}
                    Navigation=""
                    Message={message}
                    setMessage={setMessage}
                // error={Error}
                />
            )}
        </>
    )
}
export { ShippingInstructions }