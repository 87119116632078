import React, { useEffect, useState } from "react";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineEdit } from "react-icons/md";
import { PopupModal } from "../Components";
import Select from 'react-select';
import { BiSearch } from "react-icons/bi"
import { AiOutlineSync } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import ToggleSwitch from "./ToggleSwitch";
import { FormParser } from "../Components";
import { Modal } from "react-bootstrap";
import Master from "../Components/masters.json";
import axios from "axios";

const ConfigureChargesConventional = () => {
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [Loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [selectLoading, setSelectLoading] = useState(false)
    const [data, setData] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [search, setSearch] = useState({
        states: '',
        cities: '',
        areas: ''
    })
    const [nextPages, setNextPages] = useState({
        states: '',
        cities: '',
        areas: ''
    })
    const [pinSearch, setPinSearch] = useState('')
    const [readOnly, setReadOnly] = useState(true)
    const [conventionalBelow10, setConventionalBelow10] = useState({
        from_kg: 0,
        to_kg: 10,
        charges: 0,
        delivery_type: "Conventional"
    })
    const [conventionalAbove10, setConventionalAbove10] = useState({
        from_kg: 11,
        to_kg: 50,
        charges: 0,
        delivery_type: "Conventional"
    })
    const [waiverData, setWaiverData] = useState({});
    const [packingWaiverAbove, setPackingWaiverAbove] = useState();
    const [popupType, setpopupType] = useState("");
    const [error, setError] = useState('')
    const [message, setMessage] = useState("")
    const [row_Id, setRow_Id] = useState()
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({});
    const MastersModal = Master.ConventionalDeliveryCharges;
    const columns = [
        {
            name: 'S No.',
            cell: (row, index) => index + 1,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Pincode',
            cell: row => <div>{row.pincode.name} - {row.pincode.pincode}</div>,
            sortable: true,
            width: '300px'
        },
        {
            name: 'Delivery Charges',
            selector: row => <input type="number" className="form-control" value={row.delivery_charges} readOnly />,
            sortable: true,
        },
        {
            name: 'Waiver above value',
            selector: row => <input type="number" className="form-control" value={row?.conventional_delivery_waier_order_value_above} readOnly />,
            // selector: row => <div>{row.conventional_delivery_waiver?.conventional_delivery_waier_order_value_above}</div>,
            sortable: true,
        },
        {
            name: 'Delivery in Days',
            selector: row => <input type="number" className="form-control" value={row.delivery_in_days} readOnly />,
            sortable: true,
        },
        {
            name: 'Enabled',
            selector: 'is_enable',
            sortable: true,
            cell: row => <ToggleSwitch row={row} checked={row.is_enable} handleToggle={handleToggle} />,
            width: '100px'
        },
        {
            name: 'Action',
            cell: row => {
                return (
                    row_Id === row.id ?
                        <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={() => handleEdit(row)} /> : <></>
                )
            }
        }
    ];

    const getStates = async () => {
        try {
            const state_response = await axiosConfig.get(`/masters/states/?page_size=50`)
            let state_options = state_response.data.results.map((state) => ({ label: state.state_name, value: state.id }))
            setNextPages(prevState => ({ ...prevState, states: (state_response.data.next || '').replace(/^http:/i, 'https:') }));
            setStates(state_options);
        } catch (error) {
            console.log("error getting states :", error)
        }
    }

    const getCities = async () => {
        try {
            const city_response = await axiosConfig.get(`/masters/cities/?state=${selectedState ? selectedState : ''}&search=${search.cities}`)
            let city_options = city_response.data.results.map((city) => ({ label: city.city_name, value: city.id }))
            setNextPages((prevState) => ({ ...prevState, cities: (city_response.data.next || '').replace(/^http:/i, 'https:') }))
            setCities(city_options)
        } catch (error) {
            console.log("error getting cities :", error)
        }
    }

    const getAreas = async () => {
        try {
            const area_response = await axiosConfig.get(`/masters/areas/?city=${selectedCity ? selectedCity : ''}&search=${search.areas}`)
            let area_options = area_response.data.results.map((area) => ({ label: area.name, value: area.id }))
            setNextPages((prevState) => ({ ...prevState, areas: (area_response.data.next || '').replace(/^http:/i, 'https:') }))
            setAreas(area_options)
        } catch (error) {
            console.log("error getting areas :", error)
        }
    }

    const getData = async () => {
        try {
            const data_response = await axiosConfig.get(`/masters/conventional-deliverycharges/?page=${currentPage}&page_size=${RowPerPage}&search=${pinSearch}&pincodecitystate=${selectedState || ''}&pincode_city=${selectedCity || ''}&pincode=${selectedArea || ''}`)
            console.log("data_response", data_response)
            setData(data_response.data.results)
            setPackingWaiverAbove(data_response.data.results[0]?.conventional_packing_waier_order_value_above)
            setTotalRows(data_response.data.count)
        } catch (error) {
            console.log("error getting data :", error)
        }
    }

    const getCharges = async () => {
        try {
            const packing_response = await axiosConfig.get(`/masters/packing-charges-configuration/`)
            const waiver_response = await axiosConfig.get('/masters/waier-charges/')
            if (packing_response.data.results.length > 0) {
                let conventional_below_10 = packing_response.data.results.find((p) => p.delivery_type === "Conventional" && parseFloat(p.from_kg) === 0)
                let conventional_above_10 = packing_response.data.results.find((p) => p.delivery_type === "Conventional" && parseFloat(p.from_kg) === 11)
                conventional_below_10 && setConventionalBelow10({ ...conventional_below_10, from_kg: 0, to_kg: 10, delivery_type: "Conventional" })
                conventional_above_10 && setConventionalAbove10({ ...conventional_above_10, from_kg: 11, to_kg: 50, delivery_type: "Conventional" })
            }
            if (waiver_response.data.results.length > 0) {
                setWaiverData(waiver_response.data.results[0])
            }
        } catch (error) {
            console.log("error getting data :", error)
        }
    }

    const HandleChange = (type, key, e) => {
        switch (type) {
            case "setWaiverData":
                setWaiverData({ ...waiverData, [key]: e.target.value })
                break;
            case "setConventionalBelow10":
                setConventionalBelow10({ ...conventionalBelow10, [key]: e.target.value })
                break;
            case "setConventionalAbove10":
                setConventionalAbove10({ ...conventionalAbove10, [key]: e.target.value })
                break;
            case "setData":
                console.log(e.target.value, key, { ...data[0], [key]: e.target.value })
                setPackingWaiverAbove(e.target.value)
                break
            default:
                break;
        }
    }
    const HandleMouse = (row) => {
        setRow_Id(row.id)
    }
    const HandleRowmouseLeave = () => {
        setRow_Id()
    }
    const handleEdit = (row) => {
        console.log(row, "inside handleEdit")
        setShow(true)
        setFormData({
            ...row,
            "pincode": row.pincode?.pincode,
            "name": row.pincode?.name,
            "conventional_delivery_waier_order_value_above": row?.conventional_delivery_waier_order_value_above
        })
    }
    const handleClose = () => {
        setShow(false)
        setFormData({})
    }

    const formSubmit = async (Data) => {
        console.log(Data);
        delete Data.pincode;
        delete Data.name;
        let waiver_update_data = { ...Data, "conventional_delivery_waier_order_value_above": Data.conventional_delivery_waier_order_value_above };

        try {
            const res = await axiosConfig.patch(`/masters/conventional-deliverycharges/${Data.id}/`, waiver_update_data);
            console.log(res);
            setpopupType("success");
            getData();
            setShow(false);
            setMessage("Delivery charges updated successfully");
        } catch (error) {
            console.error("An error occurred:", error);
            setpopupType("error");
        }
    };


    const handleScroll = async (name) => {
        console.log("handle scroll", nextPages)
        if (!selectLoading && nextPages[name]) {
            setSelectLoading(true)
            const response = await axiosConfig.get(nextPages[name])
            setSelectLoading(false)
            name === "states" && setStates([...states, ...response.data.results.map((state) => ({ label: state.state_name, value: state.id }))])
            name === "cities" && setCities([...cities, ...response.data.results.map((city) => ({ label: city.city_name, value: city.id }))])
            name === "areas" && setAreas([...areas, ...response.data.results.map((area) => ({ label: area.name, value: area.id }))])
            setNextPages(prevValue => ({ ...prevValue, [name]: response.data.next }))
        }
    }
    const handleInputChange = (inputValue, string) => {
        setSearch((prevSearch) => ({ ...prevSearch, [string]: inputValue }));
    };
    const handleFilter = () => {
        setCurrentPage(1)
        getData()
    }
    const handlePackings = async () => {
        setLoadingButton(true);
        try {
            if (!data || data.length === 0) {
                throw new Error("There is no data to save");
            }

            let packing_waiver_to_send = {
                ...data[0],
                pincode: data[0]?.pincode?.id,
                conventional_packing_waier_order_value_above: packingWaiverAbove
            };

            delete packing_waiver_to_send.conventional_delivery_waiver;

            const chargesConfigurations = [
                {
                    data: packing_waiver_to_send, endpoint: 'conventional-deliverycharges'
                },
                { data: conventionalBelow10, endpoint: 'packing-charges-configuration' },
                { data: conventionalAbove10, endpoint: 'packing-charges-configuration' }
            ];

            for (const { data, endpoint } of chargesConfigurations) {
                if (!data) continue; // Skip if data is undefined
                const url = data.id ? `/masters/${endpoint}/${data.id}/` : `/masters/${endpoint}/`;
                const method = data.id ? 'put' : 'post';

                await axiosConfig[method](url, data);
            }

            setpopupType("success");
            setError("");
            setReadOnly(true);
            setMessage("Saved Packing Charges successfully");
        } catch (error) {
            console.log("Error updating packings", error);
            if (error.response && error.response.data && Object.values(error.response.data).length > 0) {
                console.log(Object.values(error.response.data)[0]);
                Object.values(error.response.data)[0][0] === 'A valid number is required.'
                    ? setError("All fields with '*' are mandatory")
                    : setError(Object.values(error.response.data)[0]);
            } else {
                setError(error.message || "An unexpected error occurred");
            }
            setpopupType("error");
        }
        setLoadingButton(false);
    };


    const handleToggle = async (e, obj) => {
        try {
            const response = await axiosConfig.put(`/masters/conventional-deliverycharges/${obj.id}/`, {
                ...obj,
                is_enable: e.target.checked,
                pincode: obj.pincode.pincode
            })
            getData()
            console.log(response)
        } catch (error) {
            console.log("Error toggling", error)
        }
    }

    const handleSync = async () => {
        try {
            const response = await axiosConfig.post('/masters/create_conventional_delivery_charges/',
                {
                    state: selectedState,
                    city: selectedCity,
                    area: selectedArea
                })
            getData()
            setpopupType("success")
            setError("")
            setMessage("Synced Pincodes successfully")
        } catch (error) {
            console.log("Error syncing pincodes : ", error)
            setpopupType("error")
            if (error.response.data.detail) {
                setError(error.response.data.detail)
            }
        }
    }

    const handleDownloadExcel = async () => {
        setLoadingDownload(true);
        try {
            const res = await axiosConfig.get(`masters/download_delivery_charges/?state=${selectedState || ''}&city=${selectedCity || ''}`);
            const csvData = res.data;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'delivery_charges.csv';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setLoadingDownload(false);
            setTotalRows(res.data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpload = async (event) => {
        setLoadingUpload(true)
        try {
            const selectedFile = event.target.files[0];
            console.log("Selected file:", selectedFile);

            const formData = new FormData();
            formData.append('delivery_charges', selectedFile);

            // Remove the Content-Type header setting
            delete axiosConfig.defaults.headers['Content-Type'];

            const response = await axiosConfig.post(`/masters/upload_delivery_charges_csv/`, formData);
            setpopupType("success");
            setError("");
            getData();
            setMessage('Charges uploaded Successfully');
        } catch (error) {
            console.log("Error uploading file:", error);
            setpopupType("error");
            if (error.response.data.error) {
                setError(error.response.data.error)
            }
        }
        setLoadingUpload(false);
    };

    useEffect(() => {
        getData();
    }, [currentPage, RowPerPage, pinSearch]);
    useEffect(() => {
        getStates();
        getCharges();
    }, []);
    useEffect(() => {
        getCities();
    }, [search.cities, selectedState]);
    useEffect(() => {
        getAreas();
    }, [search.areas, selectedCity]);

    return (
        <>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content mt-2">
                    <div className="report-filters mx-0">
                        <Select
                            className="flex-1"
                            placeholder="Select State"
                            options={[{ label: "All States", value: "" }, ...states]}
                            onMenuScrollToBottom={() => handleScroll("states")}
                            isLoading={selectLoading}
                            onChange={(e) => setSelectedState(e.value)}
                            onInputChange={(inputValue) => handleInputChange(inputValue, "states")}
                        />
                        <Select
                            className="flex-1"
                            placeholder="Select City"
                            options={[{ label: "All Cities", value: "" }, ...cities]}
                            onMenuScrollToBottom={() => handleScroll("cities")}
                            isLoading={selectLoading}
                            onChange={(e) => setSelectedCity(e.value)}
                            onInputChange={(inputValue) => handleInputChange(inputValue, "cities")}
                        />
                        <Select
                            className="flex-1"
                            placeholder="Select Area"
                            options={[{ label: "All Areas", value: "" }, ...areas]}
                            onMenuScrollToBottom={() => handleScroll("areas")}
                            isLoading={selectLoading}
                            onChange={(e) => setSelectedArea(e.value)}
                            onInputChange={(inputValue) => handleInputChange(inputValue, "areas")}
                        />
                        <button className="btn btn-primary" style={{ height: '37px' }} onClick={handleFilter}>Filter</button>
                    </div>
                    <div className="report-filters mx-0">
                        <div className="search-box">
                            <span className="icon-product"><BiSearch /></span>
                            <input type="text" className="input-control" placeholder="Search" name="search" onChange={(e) => setPinSearch(e.target.value)} />
                        </div>
                        <div className="sync" onClick={handleSync}>
                            <AiOutlineSync />
                        </div>
                        <button className="btn btn-primary" style={{ height: '37px' }} onClick={handleDownloadExcel}>
                            {loadingDownload ?
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                : "Bulk Download"
                            }
                        </button>
                        <div>
                            <input
                                type="file"
                                id="chargesUpload"
                                onChange={handleUpload}
                                onClick={(e) => e.target.value = null}
                                style={{ display: 'none' }}
                            />
                            <button className="btn btn-primary" style={{ height: '37px' }} onClick={() => document.getElementById('chargesUpload').click()}>
                                {loadingUpload ?
                                    <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                    : "Bulk Upload"
                                }
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <h6>Conventional packing charges</h6>
                        <div className="report-filters mx-0 align-items-end">
                            <div className="flex-1">
                                <label className="form-label">Below 10kg <span className="required-star">*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    disabled={readOnly}
                                    value={conventionalBelow10?.charges}
                                    onChange={(e) => HandleChange("setConventionalBelow10", "charges", e)}
                                />
                            </div>
                            <div className="flex-1">
                                <label className="form-label">Above 10kg <span className="required-star">*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    disabled={readOnly}
                                    value={conventionalAbove10?.charges}
                                    onChange={(e) => HandleChange("setConventionalAbove10", "charges", e)}
                                />
                            </div>
                            <div className="flex-1">
                                <label className="form-label">Packing charges waiver if order value above <span className="required-star">*</span></label>
                                <input
                                    className="form-control"
                                    type="text"
                                    disabled={readOnly}
                                    value={packingWaiverAbove}
                                    onChange={(e) => HandleChange("setData", "conventional_packing_waier_order_value_above", e)}
                                />
                            </div>
                            {readOnly ?
                                <button className="btn btn-primary" style={{ height: '37px' }} onClick={() => setReadOnly(false)}>Edit</button>
                                :
                                <button className="btn btn-primary" style={{ height: '37px' }} onClick={handlePackings}>
                                    {loadingButton ?
                                        <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                        : "Save"
                                    }
                                </button>
                            }
                        </div>
                    </div>
                    <div style={{ position: 'relative', zIndex: 0 }}>
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            // onSort={handleSort}
                            paginationServer fixedHeader customStyles={tableCustomStyles}
                            progressPending={Loading}
                            onRowMouseEnter={HandleMouse}
                            onRowMouseLeave={HandleRowmouseLeave}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <Modal
                        size="xl"
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                Edit delivery charges
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} buttonTitle='Save' />
                        </Modal.Body>
                    </Modal>
                    {popupType != "" &&
                        <PopupModal popupType={popupType} setpopupType={setpopupType} error={error} Navigation="" Message={message} />
                    }
                </div>
            </div>
        </>
    )
}

export { ConfigureChargesConventional }