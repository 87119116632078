import { useState, useEffect } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsArrowReturnRight } from "react-icons/bs";
import { PopupModal } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import { AiOutlineSync } from 'react-icons/ai'
import '../../src/Pages/Pages.css';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import Select from 'react-select';
import useDebouncedValue from "../Utils/Debounce";
import ToggleSwitch from "./ToggleSwitch";
import { useURLParams } from '../Components/UrlUtils/useURLParams';
const Inventory = () => {
    const user = JSON.parse(localStorage.getItem("user_data"))
    const { setParam, getParam, clearSelection } = useURLParams();
    const [store, setStore] = useState(getParam("store") ||JSON.parse(localStorage.getItem("user_data")).id||"" )
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [stores, setStores] = useState([])
    const [selectedStore, setSelectedStore] = useState({ value: getParam("store") || "" });
    const [Loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [LoadingButton, setLoadingButton] = useState(false);
    const [search, SetSearch] = useState({
        search: getParam("search") || ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const [popupType, setpopupType] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/catlog/storewise_inventry/?store=${store ? store : ""}&ordering=${direction === "asc" ? column.selector : -column.selector}&category=${selectedCategory ? selectedCategory : ""}&sub_category=${selectedSubCategory ? selectedSubCategory : ""}`).then(res => {
            setProducts(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        setParam("search", value)
    }
    const product_columns = [
        {
            name: 'S No.',
            sortable: false,
            cell: (row, index) => index + 1
        },
        {
            name: 'Product',
            selector: row => <div><img className="mng-prod-img" src={row.productvariant.product.image && row.productvariant.product.image?.image} alt={row.productvariant.product.name} /></div>,
            sortable: false,
        },
        {
            name: 'Name',
            selector: 'productvariant.product.name',
            sortable: true,
        },
        {
            name: 'Price',
            selector: 'productvariant.product.price',
            sortable: true,
        },
        {
            name: 'HSN/GST',
            selector: 'productvariant.product.tax_classification',
            cell: row => row.productvariant.product.tax_classification ? row.productvariant.product.tax_classification.hsn_code : 'NA',
        },
        {
            name: 'Stock',
            selector: 'avaliable',
            sortable: true,
            cell: row => <ToggleSwitch row={row} checked={row.avaliable} handleToggle={handleToggle} />,
        }
    ];

    const handleToggle = async (e, row) => {
        try {
            const update = await axiosConfig.patch(`/catlog/inventry/${row.id}/`, { avaliable: e.target.checked })
            console.log(update)
            GetData(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetCategories = async () => {
        if (categories.length > 0) return
        try {
            const response = await axiosConfig.get(`/catlog/categories/?page_size=30&is_suspended=false`)
            const subCategoryPromises = response.data.results.map(async (category) => {
                return axiosConfig.get(`/catlog/sub-categories/?category=${category.id}&is_suspended=false&page_size=100`);
            });

            const subCategoryResponses = await Promise.all(subCategoryPromises);
            const categoriesWithSubCategories = response.data.results.map((category, index) => {
                return {
                    ...category,
                    subCategories: subCategoryResponses[index].data.results.map((subCategory) => ({
                        id: subCategory.id,
                        name: subCategory.name,
                        category: category.id
                    })),
                    count: subCategoryResponses[index].data.count
                };
            });

            setCategories(categoriesWithSubCategories);
            console.log(categoriesWithSubCategories);
        } catch (error) {
            console.log(error)
        }
    }
    const GetData = async (showLoader) => {
        showLoader && setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/storewise_inventory/?store=${store ? store : ""}&page=${currentPage}&page_size=${RowPerPage}&category=${selectedCategory ? selectedCategory : ""}&sub_category=${selectedSubCategory ? selectedSubCategory : ""}`)
            setTotalRows(responce.data.count)
            setProducts(responce.data.results)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }

    }
    const GetStores = async () => {
        try {
            const responce = await axiosConfig.get(`/stores/stores/?page_size=1000`)
            console.log(responce)
            setStores(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }

    }
    const handleStoreChange = (selectedOption) => {
        setSelectedStore({ value: selectedOption.value }); // Update selected store
        setStore(selectedOption.value);
        setParam("store", selectedOption.value)
    };
    const handleSync = async () => {
        setLoadingButton(true)
        try {
            const responce = await axiosConfig.get(`/stores/stores/${store ? store : ""}/sync_products/`)
            console.log(responce)
            setLoadingButton(false)
            setpopupType("success")
            setMessage(responce.data.message)
            // window.location.reload(true)
            GetData(true);
        }
        catch (error) {
            console.log(error)
            setLoadingButton(false)
            setpopupType("error")
            console.log(error.response.data.error)
            error.response.data.error && setError(error.response.data.error)
        }
    }

    const handleFilter = (type, data) => {
        if (type === "category") {
            setSelectedCategory(data.id)
            setSelectedSubCategory(null)
        } else if (type === "subCategory") {
            if (data.category !== selectedCategory) {
                setSelectedCategory(null)
            }
            setSelectedSubCategory(data.id)
        }
    }

    useEffect(() => {
        const getSearchResults = async () => {
            try {
                axiosConfig.get(`/catlog/storewise_inventory/?store=${store ? store : ""}&search=${debouncedSearchTerm}`).then(res => {
                    setProducts(res.data.results)
                    console.log(res.data.results)
                }).catch(error => {
                    console.log(error)
                })
            } catch (error) {
                console.log(error);
            }
        };

        getSearchResults();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        GetCategories()
        user.role&&GetData(true);
        user.role&&GetStores();
    }, [currentPage, RowPerPage,store, selectedCategory, selectedSubCategory])
    useEffect(() => {
        if (user.role === "StoreManager") {
            setParam("store", JSON.parse(localStorage.getItem("Store")).id)
        }
    }, [user])
    return (
        <div className="child-sidenav">
            <div className="inventory-filters">
                <div className="filter-by p-10">FILTERED BY</div>
                <div className="p-10 rowBetweenCenter">
                    <div>
                        <div className="high-demand">High in demand 0</div>
                        <div className="f_10_green">All items are in stock</div>
                    </div>
                    <div className="rowAligned gap-10">
                        <span className="new">New</span>
                        <MdKeyboardArrowRight size={15} color="#C1C1C1" />
                    </div>
                </div>
                <div className="all-items">
                    <div className="rowAligned gap-10">
                        <div className="f_16_green">All items</div>
                        <div className="items-count">{totalRows}</div>
                    </div>
                    <MdKeyboardArrowRight size={15} color="#1B7D78" />
                </div>
                <div className="filter-by p-10">CATEGORIES</div>
                {categories.length > 0 && categories.map((category) => (
                    <>
                        <div className={`category-head ${category.id === selectedCategory ? "active" : ""}`} onClick={() => handleFilter("category", category)}>
                            <div className="f_16_5c5c5c">{category.name}</div>
                            <div className="f_12_B8ACAC">{category.count}</div>
                        </div>
                        <ul className="subcategory-list">
                            {category.subCategories.length > 0 && category.subCategories.map((subcategory) => (
                                <li className={`subcategory-head ${subcategory.id === selectedSubCategory ? "active" : ""}`} onClick={() => handleFilter("subCategory", subcategory)}>
                                    <BsArrowReturnRight size={15} color="#B8ACAC" style={{ marginRight: '10px' }} />
                                    <div className="f_12_5c5c5c">{subcategory.name}</div>
                                    {/* <div className="f_12_B8ACAC">56</div> */}
                                </li>
                            ))}
                        </ul>
                    </>
                ))}
            </div>
            <div className="childnav-content">
                {user.role === "Admin" &&
                    <div className="store-select">
                        <Select
                            options={stores.map(store => ({ value: store.id, label: store.name }))}
                            placeholder="Select Store"
                            value={stores.map(store => ({ value: store.id, label: store.name })).find(res => res.value === parseFloat(selectedStore.value))}
                            onChange={(selectedOption) => { if (!selectedOption) { clearSelection(setSelectedStore, "store") } else { handleStoreChange(selectedOption) } }}
                            isClearable
                        />
                    </div>}
                <div>
                    <div className="prod-search">
                        <input type="text" className="form-control prod-search-input" placeholder="Enter product name" name="search" value={search.search} onChange={HandleSearch} />
                        <div className="sync" onClick={handleSync}>
                            {LoadingButton ? <div>
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                            </div> : <AiOutlineSync />}

                        </div>
                    </div>
                    {store ?
                        <DataTable
                            title={
                                <div className="datatable-title">
                                    Total Items({totalRows})
                                </div>
                            }
                            columns={product_columns}
                            data={products}
                            pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader customStyles={tableCustomStyles}
                            progressPending={Loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." />
                            </div>}
                        />
                        :
                        <div className="text-center mt-5 f_14_500">Select Store to view products</div>
                    }
                </div>
            </div>
            {popupType != "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} Message={message} error={error} />
            }
        </div>
    )
}

export { Inventory }