import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import BreadCrumb from "../Components/BredCrumbs/BredCrumbs";
import { useLocation } from 'react-router-dom';
import { data } from "jquery";
const ReferralDiscount = () => {
    const location = useLocation();
    let today_date = (new Date()).toISOString().split('T')[0];
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const MastersModal = Masters.ReferralDiscount
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("Referral Discount");
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({
        from_date:"",
        to_date:""
    });
    const handleClose = () => {
        setShow(false)
        // SetError("")
        setpopupType("")
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/catlog/referral-discounts/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}&page_size=${RowPerPage}&coupon_end_date_after=${formData.from_date}&coupon_end_date_before=${formData.to_date}&fields_coupon=start_date,end_date,code,description,active,is_auto_apply,can_apply_with_other_coupons,user_redeem_limit,is_display_coupon,id`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (FormData, id) => {
        console.log(FormData, "formdata")
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (FormData.id) {
            let newFormData = FormData;
            if (typeof (FormData.image) == "string") {
                delete newFormData.image;
            }
            axiosConfig.patch(`/catlog/referral-discounts/${FormData.id}/`, { ...FormData, image: FormData.image?.id })
                .then(res => {
                    console.log(res)
                    setpopupType("success")
                    setPasswordError("");
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error)
                    if (error.response && error.response.status === 400) {
                        const errorMessage = error.response.data.content;
                        console.log(errorMessage[0])
                        if (errorMessage[0] === "This field may not be blank.") {
                            setPasswordError('description may not be blank.');
                        }
                    }

                    setpopupType("error")
                })
        } else {
            axiosConfig.post("/catlog/referral-discounts/", { ...FormData, image: FormData.image?.id }).then(res => {
                console.log(res)
                setpopupType("success")
                setPasswordError("");
                GetData();
                setShow(false);
            }).catch(error => {
                console.log(error)
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.content;
                    console.log(errorMessage[0])
                    if (errorMessage[0] === "This field may not be blank.") {
                        setPasswordError('description may not be blank.');
                    }
                }
                setpopupType(error)
            })
        }
    };
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/catlog/referral-discounts/?page=${currentPage}&search=${debouncedSearchTerm}&page_size=${RowPerPage}&coupon_start_date=${formData.from_date}&coupon_end_date=${formData.to_date}&fields_coupon=start_date,end_date,code`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage,debouncedSearchTerm]);
    const [blogid, setBlogid] = useState()
    function extractDate(timestamp) {
        const date = new Date(timestamp);
    
        // Extracting the date in 'YYYY-MM-DD' format based on local time
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "Active",
            selector: "active",
            cell: row => `${row.coupon?.start_date ? extractDate(row.coupon?.start_date) : "N/A"}-${row.coupon?.end_date ? extractDate(row.coupon?.end_date) : "N/A"}`,
            sortable: true
        },
        {
            name: "maximum discount amount",
            selector: "maximum_discount_amount",
            cell: row => row.maximum_discount_amount,
            sortable: true
        },
        {
            name: "referee discount amount",
            selector: "referee_discount_amount",
            cell: row => row.referee_discount_amount ? row.referee_discount_amount : 0,
            sortable: true,
        },
        {
            name: "referee discount percent",
            selector: "referee_discount_percent",
            cell: row => row.referee_discount_percent ? `${row.referee_discount_percent}%` : 0,
            sortable: true
        },
        {
            name: "referrer discount amount",
            selector: "referrer_discount_amount",
            cell: row => row.referrer_discount_amount ? row.referrer_discount_amount : 0,
            sortable: true,
        },
        {
            name: "referrer discount percent",
            selector: "referrer_discount_percent",
            cell: row => row.referrer_discount_percent ? `${row.referrer_discount_percent}%` : 0,
            sortable: true
        },
        // {
        //     name: "ACTIONS",
        //     selector: row => {
        //         const HandleEdit = async () => {
        //             console.log(row.id, 'iddddd')
        //             // setShow(true)
        //             blogedit(row.id)
        //             // handleShow(row.id);
        //             setModalTitle("Edit Referral Discount");
        //         }
        //         const DeleteAlert = () => {
        //             SetRowId(row.id)
        //             SetAlertDeletePopupType("success")
        //         }
        //         const CancelAlerrt = () => {
        //             SetAlertDeletePopupType("error")
        //         }
        //         const HandleDelete = async (id) => {
        //             await axiosConfig.delete(`/catlog/referral-discounts/${id}/`).then(() => {
        //                 SetAlertDeletePopupType("error")
        //                 setDeletepopupType("success")
        //                 GetData();
        //             }).catch((error) => {
        //                 setDeletepopupType("error")
        //                 SetAlertDeletePopupType("error")
        //                 console.log(error)
        //             })
        //         }
        //         const handleRevive = async () => {
        //             try {
        //                 const response = await axiosConfig.post(`/catlog/referral-discounts/${row.id}/revive/`)
        //                 setpopupType("success")
        //                 setMessage("Blog revived successfully")
        //                 GetData()
        //             } catch (error) {
        //                 console.log("Error While Reviving", error)
        //             }
        //         }
        //         return (
        //             <>
        //                 {!row.is_suspended ?
        //                     <div className="row" >
        //                         <div className="col-4">
        //                             <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
        //                         </div>
        //                         <div className="col-4">
        //                             <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
        //                         </div>
        //                     </div>
        //                     :
        //                     <>
        //                         <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
        //                     </>
        //                 }
        //                 {
        //                     AlertDeletePopupType === "success" &&
        //                     <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
        //                 }
        //             </>
        //         )

        //     }
        // }
    ]
    const prev_sub_page = {
        link:location?.pathname,
        text:"Referral Discount",
    };
    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                    <h5 className="dashboard-subtitle">Referral Discount</h5>
                    {/* <div className="cat-button">
                         <div className="btn" onClick={handleShow} >
                            + Add New
                        </div>
                    </div>  */}
                    {/* <section className="about-breadcrumb">
                        <BreadCrumb active_page="referral_discount" prev_sub_page={prev_sub_page} />
                    </section> */}
                    <div className="row">
                        <div className="col-3 form-group">
                            <input
                                className="form-control"
                                type="date"
                                name="from_date"
                                value={formData.from_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-3 form-group">
                            <input
                                className="form-control"
                                type="date"
                                name="to_date"
                                value={formData.to_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-1">
                        <div>
                            <button className="btn btn-primary" style={{ minHeight: '37px' }} onClick={GetData}>Filter</button>
                        </div>
                        </div>
                        <div className="col-5">
                        <div className="input-cat-with-icon">
                        <span className="icon-product"><BiSearch /></span>
                        <input type="text" className="input-control" placeholder="Search" name="search" value={search.search} onChange={HandleSearch} />
                    </div> 
                    </div>
                    </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {passwordError && (
                                    <div className="alert alert-danger" role="alert">
                                        {passwordError}
                                    </div>
                                )}

                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export { ReferralDiscount }