import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  createContext,
} from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { RiBillFill, RiCustomerServiceFill } from "react-icons/ri";
import { MdOutlineColorLens } from "react-icons/md";
import {
  MdOutlineDashboard,
  MdManageAccounts,
  MdCalendarMonth,
  MdList,
  MdOutlineNotifications,
  MdLogout,
} from "react-icons/md";
import { TbReport, TbReportSearch } from "react-icons/tb";
import { BiBarChartSquare, BiStore } from "react-icons/bi";
import { MdCircleNotifications } from "react-icons/md";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { RxBarChart } from "react-icons/rx";
import { FaCommentMedical, FaClinicMedical } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import "./DashboardLayout.css";
import sidebar from "./sidebar";
import { ChildNav } from "./ChildNav";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ChartTable from "./Chart";
import { CgMenuBoxed, CgProfile } from "react-icons/cg";
import { NavLink, Outlet } from "react-router-dom";
import axiosConfig from "../../Service/axiosConfig";
import Notification1 from "../DashboardLayout/sounds/notify1.mp3";
import Notification2 from "../DashboardLayout/sounds/notify2.mp3";
import { TiVendorMicrosoft } from "react-icons/ti";
const reloadContext = createContext();
export const useReloadContext = () => {
  return useContext(reloadContext);
};
const DashboardLayout = ({ children }) => {
  const Navigate = useNavigate();
  const [smShow, setSmShow] = useState(false);
  const [childData, setChildData] = useState([])
  const [Toggle, SeToggle] = useState(true)
  const [ParentNav, SetParentNav] = useState("")
  const [ChildNavs, SetChildNavs] = useState("")
  const [UserData, SetUserData] = useState({})
  const scrollableContainerRef = useRef(null);
  const orderCountRef = useRef(null)
  const [siteSettings, setSiteSettings] = useState({})
  const [reload, setReload] = useState(false)
  const audio = new Audio(Notification2);
  const [isOnline, setIsOnline] = useState(true);
  const [DataCount, setDataCount] = useState('')
  const handleNav = (event, url) => {
    let parentnav = event.currentTarget.dataset.parentnav
    localStorage.setItem('ParentNav', parentnav);
    localStorage.setItem("url", url)
    if (parentnav === "masters" || parentnav === "accounts") {
      setChildData(sidebar[parentnav])
      SetParentNav(parentnav)
    }
  }
  const AlertLogout = () => {
    setSmShow(!smShow)
  }
  const handleLogout = (event) => {
    localStorage.removeItem('token');
    localStorage.removeItem('Store');
    setSmShow(false)
    Navigate("/")
  }
  const getUserData = async () => {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    SetUserData(user_data);
  }
  const Store_id = JSON.parse(localStorage.getItem('Store'))?.id;
  const getParentNav = async () => {
    let parentnav = localStorage.getItem('ParentNav')
    let childnavpath = localStorage.getItem('ChildNavs')
    if (parentnav === "masters" || parentnav === "accounts") {
      SetChildNavs(childnavpath)
      setChildData(sidebar[parentnav])
    }
  }
  const childNavClick = (childnavpath) => {
    localStorage.setItem('ChildNavs', childnavpath);
    SetChildNavs(childnavpath)
  }
  const GetSiteSettings = async () => {
    try {
      const responce = await axiosConfig.get(`/sitedata/site/`)
      console.log(responce.data, "site settings")
      if (responce.data.results.length > 0) {
        setSiteSettings(responce.data.results[0])
        document.title = responce.data.results[0].title;
        const favicon =
          document.querySelector("link[rel='icon']") ||
          document.createElement("link");
        favicon.rel = "icon";
        favicon.href =
          JSON.parse(localStorage.getItem("site_data"))?.id ===
            responce.data.results[0]?.id
            ? JSON.parse(localStorage.getItem("site_data")).favicon.image
            : responce.data.results[0].favicon.image;
        document.head.appendChild(favicon);
      }
    }
    catch (error) {
      console.log(error)
    }

  }
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0, so we add 1 and pad with 0 if necessary
  const day = String(today.getDate()).padStart(2, '0'); // Pad with 0 if necessary
  const formatDate = `${year}-${month}-${day}`;
  const AutoReload = () => {
    const interval = setInterval(async () => {
      if (isOnline) {
        try {
          const responce = await axiosConfig.get(`accounts/today_order_count/?store=${Store_id ? Store_id : ''}&start_date=${formatDate}&end_date=${formatDate}`)
          console.log(responce.data.Placed, orderCountRef.current, reload, "dashboard reload")
          console.log(orderCountRef.current !== null && orderCountRef.current !== responce.data.Placed)
          console.log(orderCountRef.current === null);
          if (orderCountRef.current !== null && orderCountRef.current !== responce.data.Placed) {
            orderCountRef.current = responce.data.Placed;
            setDataCount(responce.data.Placed)
            setReload(true);
            audio.play();
          } else if (orderCountRef.current === null) {
            orderCountRef.current = responce.data.Placed;
          }
        }
        catch (error) {
          console.log(error)
        }
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    }
  }

  const toggleOnline = async () => {
    try {
      setIsOnline(!isOnline);
      let is_online = !isOnline ? 'online' : 'offline'
      const store_data = (await axiosConfig.get(`/stores/stores/${Store_id}/`)).data
      delete store_data.logo_image;
      delete store_data.banner_image;
      console.log(store_data, "store data")
      const response = await axiosConfig.put(`/stores/stores/${Store_id}/`, { ...store_data, is_online: is_online });
      console.log(response, "is online")
    }
    catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    AutoReload();
    getUserData();
    getParentNav();
    GetSiteSettings();
  }, []);

  return (
    <>
      <div className="dashboard-layout">
        <header>
          <div className="Dashboard-brand">
            <img
              className="DashBoard-Logo"
              src={
                localStorage.getItem("site_data")
                  ? JSON.parse(localStorage.getItem("site_data")).logo.image
                  : siteSettings.logo
                    ? siteSettings.logo.image
                    : require("./images/Logo.png")
              }
              alt="logo"
            />
          </div>
          <div className="ms-auto menu-right">
            {UserData?.role !== "Admin" && (
              <div
                className={`${isOnline ? "user-status" : "user-status offline"
                  }`}
                onClick={toggleOnline}
              >
                <p className={`${isOnline ? "user-online" : "user-offline"}`}>
                  {isOnline ? "ONLINE" : "OFFLINE"}
                </p>
                <img
                  className={`DashBoard-Photo ${isOnline ? "" : "offline"}`}
                  src={require("./images/Photo.png")}
                  alt="userPhoto"
                />
              </div>
            )}
            <RiCustomerServiceFill className="DashBoard-Notification" />
            <MdOutlineNotifications className="DashBoard-Notification" />
            <MdLogout
              className="DashBoard-Notification"
              onClick={AlertLogout}
            />
          </div>
        </header>
        <aside>
          <nav className="sidebar-main">
            {UserData && UserData.role === "Admin" && (
              <>
                <NavLink
                  to="/dashboard/charts"
                  className={
                    localStorage.getItem("ParentNav") == "Dashboard"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Dashboard")}
                    className={
                      localStorage.getItem("ParentNav") == "Dashboard"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Dashboard"
                  >
                    <RxBarChart className="sidebar-icon" />
                    <p className="sidebar-name">Dashboard</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/orders"
                  className={
                    localStorage.getItem("ParentNav") == "Appointments"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Appointments")}
                    className={
                      localStorage.getItem("ParentNav") == "Appointments"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Appointments"
                  >
                    <TbReport className="sidebar-icon" />
                    <p className="sidebar-name">Orders</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/reports"
                  className={
                    localStorage.getItem("ParentNav") == "Patients"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Patients")}
                    className={
                      localStorage.getItem("ParentNav") == "Patients"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Patients"
                  >
                    <BiBarChartSquare className="sidebar-icon" />
                    <p className="sidebar-name">Reports</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/categories"
                  className={
                    localStorage.getItem("ParentNav") == "billing"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "billing")}
                    className={
                      localStorage.getItem("ParentNav") == "billing"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="billing"
                  >
                    <CgMenuBoxed className="sidebar-icon" />
                    <p className="sidebar-name">Catlog</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/blogposts"
                  className={
                    localStorage.getItem("ParentNav") == "marketing"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "marketing")}
                    className={
                      localStorage.getItem("ParentNav") == "marketing"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="marketing"
                  >
                    <HiOutlineSpeakerphone className="sidebar-icon" />
                    <p className="sidebar-name">Marketing</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/homedesign"
                  className={
                    localStorage.getItem("ParentNav") == "CMS"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "CMS")}
                    className={
                      localStorage.getItem("ParentNav") == "CMS"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="CMS"
                  >
                    <MdOutlineColorLens className="sidebar-icon" />
                    <p className="sidebar-name">CMS</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/sitesettings"
                  className={
                    localStorage.getItem("ParentNav") == "settings"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "settings")}
                    className={
                      localStorage.getItem("ParentNav") == "settings"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="settings"
                  >
                    <AiOutlineSetting className="sidebar-icon" />
                    <p className="sidebar-name">Settings</p>
                  </div>
                </NavLink>
                {/* <NavLink to="/dashboard/admin/profile" className={localStorage.getItem('ParentNav') == "reports" ? "sidebar-icon acitve" : "sidebar-icon"}><div onClick={(e) => handleNav(e, "reports")} className={localStorage.getItem('ParentNav') == "reports" ? "navitem active" : "navitem"} data-parentnav="reports" >
                                        <BiStore className="sidebar-icon" />
                                        <p className="sidebar-name">Stores</p>
                                    </div></NavLink> */}
              </>
            )}
             {(UserData && (UserData.role === "opoto_staff" || UserData.role === "manager"))&& (
              <>
                <NavLink
                  to="/dashboard/charts"
                  className={
                    localStorage.getItem("ParentNav") == "Dashboard"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Dashboard")}
                    className={
                      localStorage.getItem("ParentNav") == "Dashboard"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Dashboard"
                  >
                    <RxBarChart className="sidebar-icon" />
                    <p className="sidebar-name">Dashboard</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/orders"
                  className={
                    localStorage.getItem("ParentNav") == "Appointments"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Appointments")}
                    className={
                      localStorage.getItem("ParentNav") == "Appointments"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Appointments"
                  >
                    <TbReport className="sidebar-icon" />
                    <p className="sidebar-name">Orders</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/reports"
                  className={
                    localStorage.getItem("ParentNav") == "Patients"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Patients")}
                    className={
                      localStorage.getItem("ParentNav") == "Patients"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Patients"
                  >
                    <BiBarChartSquare className="sidebar-icon" />
                    <p className="sidebar-name">Reports</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/categories"
                  className={
                    localStorage.getItem("ParentNav") == "billing"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "billing")}
                    className={
                      localStorage.getItem("ParentNav") == "billing"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="billing"
                  >
                    <CgMenuBoxed className="sidebar-icon" />
                    <p className="sidebar-name">Catlog</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/blogposts"
                  className={
                    localStorage.getItem("ParentNav") == "marketing"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "marketing")}
                    className={
                      localStorage.getItem("ParentNav") == "marketing"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="marketing"
                  >
                    <HiOutlineSpeakerphone className="sidebar-icon" />
                    <p className="sidebar-name">Marketing</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/homedesign"
                  className={
                    localStorage.getItem("ParentNav") == "CMS"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "CMS")}
                    className={
                      localStorage.getItem("ParentNav") == "CMS"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="CMS"
                  >
                    <MdOutlineColorLens className="sidebar-icon" />
                    <p className="sidebar-name">CMS</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/sitesettings"
                  className={
                    localStorage.getItem("ParentNav") == "settings"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "settings")}
                    className={
                      localStorage.getItem("ParentNav") == "settings"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="settings"
                  >
                    <AiOutlineSetting className="sidebar-icon" />
                    <p className="sidebar-name">Settings</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/vendors/daily-collections"
                  className={
                    localStorage.getItem("ParentNav") == "Vendors"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Vendors")}
                    className={
                      localStorage.getItem("ParentNav") == "Vendors"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Vendors"
                  >
                    <TiVendorMicrosoft className="sidebar-icon" />
                    <p className="sidebar-name">Vendor's</p>
                  </div>
                </NavLink>
                
              </>
            )}
            {UserData && UserData.role === "StoreManager" && (
              <>
                <NavLink
                  to="/dashboard/orders"
                  className={
                    localStorage.getItem("ParentNav") == "Appointments"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Appointments")}
                    className={
                      localStorage.getItem("ParentNav") == "Appointments"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Appointments"
                  >
                    <TbReport className="sidebar-icon" />
                    <p className="sidebar-name">Orders</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/reports"
                  className={
                    localStorage.getItem("ParentNav") == "Patients"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "Patients")}
                    className={
                      localStorage.getItem("ParentNav") == "Patients"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="Patients"
                  >
                    <TbReportSearch className="sidebar-icon" />
                    <p className="sidebar-name">Reports</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/masters/inventory"
                  className={
                    localStorage.getItem("ParentNav") == "billing"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "billing")}
                    className={
                      localStorage.getItem("ParentNav") == "billing"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="billing"
                  >
                    <CgMenuBoxed className="sidebar-icon" />
                    <p className="sidebar-name">Menu</p>
                  </div>
                </NavLink>
                <NavLink
                  to="/dashboard/store_management"
                  className={
                    localStorage.getItem("ParentNav") == "reports"
                      ? "sidebar-icon acitve"
                      : "sidebar-icon"
                  }
                >
                  <div
                    onClick={(e) => handleNav(e, "reports")}
                    className={
                      localStorage.getItem("ParentNav") == "reports"
                        ? "navitem active"
                        : "navitem"
                    }
                    data-parentnav="reports"
                  >
                    <CgProfile className="sidebar-icon" />
                    <p className="sidebar-name">Profile</p>
                  </div>
                </NavLink>
              </>
            )}
          </nav>
        </aside>
        <div
          className="dashboard-content"
          id="scroll"
          ref={scrollableContainerRef}
        >
          <reloadContext.Provider value={{ reload, setReload, formatDate }}>
            {children}
            <Outlet />
          </reloadContext.Provider>
        </div>
      </div>
      <Modal
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="success-icon">
          <h5 className="successmsg">Are you sure you want to logout ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            className="btn btn-primary"
            onClick={() => setSmShow(false)}
          >
            Cancel
          </button>
          <button
            variant="primary"
            className="btn btn-primary"
            onClick={handleLogout}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export { DashboardLayout };
