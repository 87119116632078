import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import DataTable from "react-data-table-component";
import Masters from "../Components/masters.json";
import { DeletePopupModal, PopupModal, FormParser } from "../Components";
import { BiSearch } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { CMSNavbarComponent } from "../Components/navbar";
import { VendorsNav } from "../Components/navbar/VendorsNav";
const VendorsPayments = () => {
  const [Loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [LoadingButton, setLoadingButton] = useState();
  const [Data, SetData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  const [popupType, setpopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const MastersModal = Masters.VendorsInvoice;
  const [deletePopupType, setDeletepopupType] = useState("");
  const [Error, SetError] = useState();
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [search, SetSearch] = useState({
    search: "",
  });
  const debouncedSearchTerm = useDebouncedValue(search.search, 500);
  const HandleSearch = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);
    SetSearch({ ...search, [name]: value });
  };

  const VendorInvoice_columns = [
    {
      name: "ID",
      cell: (row, index) => index + 1,
    },
    {
      name: "Vendor",
      cell: (row) => row.vendor?.contact_name,
      sortable: true,
    },
    {
      name: "Received Date",
      cell: (row) => row.vendor_invoice?.received_date,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      cell: (row) => row.vendor_invoice?.invoice_amount,
      sortable: true,
    },
    {
      name: "Return Item Worth",
      cell: (row) => row.vendor_invoice?.return_item_worth,
      sortable: true,
    },
    {
      name: "Final Amount",
      cell: (row) => row.vendor_invoice?.final_amount,
      sortable: true,
    },
    {
      name: "PDF Path",
      selector: "pdf_path",
      cell: (row) => (
        <img
          src={row.vendor_invoice?.pdf_path}
          alt="PDF Thumbnail"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      name: "Remarks",
      cell: (row) => row.vendor_invoice?.remarks,
      sortable: true,
    },
    {
      name: "Branch",
      cell: (row) => row.vendor_invoice?.branch,
      sortable: true,
    },
    {
      name: "Invoice Status",
      cell: (row) => row.vendor_invoice?.invoice_status,
      sortable: true,
    },

    {
      name: "ACTIONS",
      selector: (row) => {
        const HandleEdit = async () => {
          setShow(true);
          vendorInvoiceEdit(row.id);
          handleShow(row.id);
          setModalTitle("Edit vendors Invoice");
        };
        const DeleteAlert = () => {
          SetRowId(row.id);
          SetAlertDeletePopupType("success");
        };
        const CancelAlerrt = () => {
          SetAlertDeletePopupType("error");
        };
        const HandleDelete = async (id) => {
          await axiosConfig
            .patch(`/accounts/vendor-payments/${row.id}/`,{"payment_pass":true})
            .then(() => {
              setpopupType("success");
              GetvendorInvoice();
            })
            .catch((error) => {
              setDeletepopupType("error");
              SetAlertDeletePopupType("error");
            });
        };
        const vendorInvoiceEdit = (id) => {
          axiosConfig
            .get(`/accounts/vendor-payments/${id}/`)
            .then((res) => {
              console.log(res.data, "edit");
              setFormData({
                ...res.data,
                vendor: res.data.vendor?.id,
              });
              setShow(true);
              setMessage("Record Updated Successfully");
            })
            .catch((error) => {
              console.log(error);
            });
        };
        return (
           <>
            <div className="row">
              <div className="col-12">
                <div class="form-check">
                  <input class="form-check-input" style={{width:"24px",height:"24px",border:"1px solid black"}} type="checkbox" checked={row.payment_pass?true:false} disabled={row.payment_pass} id="flexCheckDefault" onClick={()=>HandleDelete()}/>
                </div>
              </div>
              </div>
              {AlertDeletePopupType === "success" && (
                <AlertDeletePopupModal
                  AlertDeletePopupType={AlertDeletePopupType}
                  HandleDelete={HandleDelete}
                  CancelAlerrt={CancelAlerrt}
                  row={RowId}
                />
              )}
            </>
            );
       },
     },
            ];
  const GetvendorInvoice = async () => {
    try {
              let response = await axiosConfig.get(
            `/accounts/vendor-payments/?page=${currentPage}&page_size=${RowPerPage}&search=${debouncedSearchTerm}`
            );
            console.log(response, "data");
            SetData(response.data.results);
            setLoading(false);
            setTotalRows(response.data.count);
    } catch (error) {
              console.log(error);
    }
  };
  useEffect(() => {
              GetvendorInvoice();
  }, [currentPage, RowPerPage, debouncedSearchTerm]);
  const formSubmit = async (FormData) => {
              console.log(FormData, "formdata");
            const data_to_send = {
              ...FormData,
    };
            delete data_to_send.cuser;
            delete data_to_send.muser;
            if (typeof FormData.pdf_path === "string") {
              delete data_to_send.pdf_path;
    }
            axiosConfig.defaults.headers["Content-Type"] = "multipart/form-data";
            setLoadingButton(true);
            if (FormData.id) {
              await axiosConfig
                .patch(`/accounts/vendor-payments/${FormData.id}/`, data_to_send)
                .then((res) => {
                  console.log(res);
                  setpopupType("success");
                  GetvendorInvoice();
                  setShow(false);
                  setMessage("Record Updated Successfully");
                })
                .catch((error) => {
                  console.log(error);
                  let error_message = error.response.data;
                  Object.values(error_message)[0] &&
                    SetError(Object.values(error_message)[0]);
                  setpopupType("error");
                });
            setLoadingButton(false);
    } else {
              await axiosConfig
                .post("/accounts/vendor-payments/", data_to_send)
                .then((res) => {
                  console.log(res);
                  setpopupType("success");
                  GetvendorInvoice();
                  setShow(false);
                })
                .catch((error) => {
                  console.log(error);
                  let error_message = error.response.data;
                  Object.values(error_message)[0] &&
                    SetError(Object.values(error_message)[0]);
                  setpopupType(error);
                });
            setLoadingButton(false);
    }
  };
  const handleClose = () => {
              setShow(false);
            SetError("");
            setpopupType("");
            setMessage("");
            setLoadingButton(false);
  };
  const handleSort = (column, direction) => {
              console.log(column.selector, direction);
            axiosConfig
            .get(
            `/accounts/vendor-payments/?page=${currentPage}&page_size=${RowPerPage}&ordering=${
              direction === "asc" ? column.selector : -column.selector
            }`
            )
      .then((res) => {
              SetData(res.data.results);
      })
      .catch((error) => {
              console.log(error);
      });
            setCurrentPage(1);
  };
  const handleShow = () => {
              setShow(true);
    // setFormData({

              // });
              setModalTitle("Add vendors Invoice");
  };
            const tableCustomStyles = {
              rows: {
              style: { },
    },
            cells: {
              style: {
              justifyContent: "center",
      },
    },
            headCells: {
              style: {
              color: "#5C5C5C",
            justifyContent: "center",
            color: "rgb(33,37,41)",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "28px",
      },
    },
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const downloadExcel = async () => {
    try {
      const response = await axiosConfig.get('/accounts/export-vendor-invoices/', {
        responseType: 'blob', // Important for binary data
      });
  
      // Create a URL for the blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoices.xlsx'); // File name
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  const HandleMultPass = async (ids) => {
    await axiosConfig
      .patch(`/accounts/update_invoice_status/`,{"invoice_ids":ids})
      .then(() => {
        SetAlertDeletePopupType("error");
        setpopupType("success")
        GetvendorInvoice();
        setClearRows(true);
        setTimeout(() => {
          downloadExcel();
        }, 5000);
      })
      .catch((error) => {
        setDeletepopupType("error");
        SetAlertDeletePopupType("error");
      });
  };
  const handleRowSelected = (state) => {
    // Collect IDs of the selected rows
    const ids = state.selectedRows.map((row) => row.id);
  
    // Pass the selected IDs to the handler function
    HandleMultPass(ids);
     
    // Update the selected rows state
    setSelectedRows(state.selectedRows);
  };
            return (
            <>
              <div className="child-sidenav">
                <VendorsNav />
                <div className="childnav-content">
                  <div className="d-flex align-items-center">
                    <div className="col-4 input-cat-with-icon">
                      <span className="icon-product">
                        <BiSearch />
                      </span>
                      <input
                        type="text"
                        className="input-control"
                        placeholder="Search"
                        name="search"
                        value={search.search}
                        onChange={HandleSearch}
                      />
                    </div>
                  </div>
                  <div className="table-responsive marginTop">
                    <DataTable
                      columns={VendorInvoice_columns}
                      data={Data}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationPerPage={RowPerPage}
                      paginationDefaultPage={currentPage}
                      onChangePage={(page) => setCurrentPage(page)}
                      paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                      paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                      onChangeRowsPerPage={(RowsPerPageChange) =>
                        setRowPerPage(RowsPerPageChange)
                      }
                      onSort={handleSort}
                      fixedHeader
                      subHeader
                      customStyles={tableCustomStyles}
                      progressPending={Loading}
                      selectableRows // Enables row selection
                      onSelectedRowsChange={handleRowSelected}
                      selectableRowDisabled={(row) => row.payment_pass === true}
                      clearSelectedRows={selectedRows.length === 0||clearRows}
                      progressComponent={
                        <div className="loader-container">
                          <img
                            src={require("../Components/FormParser/images/loader2.gif")}
                            alt="Loading..."
                          />
                        </div>
                      }
                    />
                  </div>
                  <Modal
                    size="xl"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        {modalTitle}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormParser
                        modelObject={MastersModal}
                        formData={formData}
                        formSubmit={formSubmit}
                        error={Error}
                        buttonTitle="Save"
                        LoadingButton={LoadingButton}
                      />
                    </Modal.Body>
                  </Modal>
                  {popupType !== "" && (
                    <PopupModal
                      setpopupType={setpopupType}
                      popupType={popupType}
                      Navigation=""
                      Message={message}
                      error={Error}
                      setMessage={setMessage}
                    />
                  )}
                  {deletePopupType !== "" && (
                    <DeletePopupModal
                      setDeletepopupType={setDeletepopupType}
                      deletePopupType={deletePopupType}
                    />
                  )}
                </div>
              </div>
            </>
            );
};
            export {VendorsPayments};
