import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const StoreManagement = () => {
    const MastersModal = Masters.StoreManager
    const [Data, SetData] = useState([])
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const user_data = JSON.parse(localStorage.getItem('user_data'))
    const Store_Id = JSON.parse(localStorage.getItem('Store'))?.id
    let { storeId } = useParams()
    let id = user_data.role === 'Admin' ? storeId : Store_Id;
    const formSubmit = (Data, setFieldErrors) => {
        console.log(Data)
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        axiosConfig.put(`/stores/stores/${id}/`, Data).then(res => {
            console.log(res)
            setpopupType("success")
        }).catch(error => {
            console.log(error)
            // Object.keys(error.response.data).forEach(fieldName => {
            //     switch (fieldName) {
            //         case 'country_name':
            //             if (error.response.data[fieldName][0] === "priority with this code already exists.") {
            //                 setFieldErrors(prev => ({ ...prev, [fieldName]: `${fieldName} Already Exists` }));
            //             }
            //             break;
            //         default:
            //             break;
            //     }
            // });

            const ErrorData = []
            ErrorData.push({
                code: error.response.data.code || ["null"],
                name: error.response.data.name || ["null"],
            })
            console.log(ErrorData[0])
            if (ErrorData[0].code[0] === "priority with this code already exists.") {
                SetError(" code already exists.")
            }
            if (ErrorData[0].name[0] === "priority with this name already exists.") {
                SetError("Tittle already Exists")
            }
            setpopupType("error")
        })
    }
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/stores/stores/${id}/`)
            console.log(responce)
            SetData(responce.data)
        }
        catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <div className="dashboard-header">
                <h5 className="dashboard-subtitle">Edit store {id}</h5>
                <NavLink to="/dashboard/masters/inventory" ><button className="btn btn-white">Manage Products</button></NavLink>
                <NavLink to="/dashboard/orders" ><button className="btn btn-white">Manage Orders</button></NavLink>
            </div>
            <FormParser modelObject={MastersModal} formData={Data} formSubmit={formSubmit} error={Error} />
            {popupType != "" &&
                <PopupModal popupType={popupType} />
            }
        </>
    )
}

export { StoreManagement }